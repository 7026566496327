<template>
    <v-container fluid>
        <!-- <Breadcrumbs /> -->
        <!-- Knowlarity Creds Input -->
        <v-card class="rounded d-flex flex-row flex-lg-wrap">
            <v-row class="ma-0">
                <v-col>
                    <v-card color="transparent" elevation="0" style="padding: 10px; text-align: center !important">
                        <v-card-text style="margin-bottom: 25px">
                            <!-- <h2 style="margin-bottom: 30px">
                        Lorem ipsum
                      </h2> -->
                            <div align="center" style="margin-bottom: 10px">
                                <h1 style="margin-bottom: 40px">Godial + Pabbly</h1>
                            </div>
                            <div style="padding: 5px 15px; text-align: center">
                                <!-- <h5
                          style="
                            line-height: 17px;
                            font-weight: 500;
                            font-size: 14px;
                          "
                        >
                          How to use?
                        </h5> -->

                                <div style="text-align: left">
                                    <p style="
                              margin-top: 20px;
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                            ">
                                        How to integrate pabbly with godial?
                                    </p>
                                    <p>
                                        1. Create a list in Godial. Goto CRM > New list
                                        <br />
                                        2. Go to <a href="https://www.pabbly.com/">Pabbly</a> and signin to your pabby
                                        dashboard. Now goto Pabbly Form Builder and setup a new form.
                                        <br />
                                        3. After creating the form goto Pabbly Connect and click on Create Workflow. Now
                                        name it and search for "Pabbly form Builder" in order to connect your form with
                                        the
                                        workflow and choose "New Form Submission" as trigger event.
                                        <br />
                                        4. Now you will get an webhook URl. Simply copy and goto the Pabbly Form Builder
                                        >
                                        Integrations > Webhook > Add webhook and paste the copied webhook url.
                                        <br />
                                        5. Now come to the workflow that is created in Pabbly connect and click on
                                        "Capture
                                        Webhook Response". You have to enter some test data in the form to check if you
                                        are
                                        receiving response or not. You will see that data under response section.
                                        <br />
                                        6. Now choose an action under the "Choose your next application" and search for
                                        "Godial" and choose "Add a contact" as action event. Click on connect and you
                                        will
                                        be required to enter an access token.
                                        <br />
                                        7. In order to get the access token goto Godial dashboard > Integration > API
                                        and
                                        Connectors > External API > API Token and copy that token.
                                        <br />
                                        8. Now goto pabbly connect workflow and choose "Add New Connection" and add that
                                        access token in to the required field and click on save.
                                        <br />
                                        9. Now you have to map your data into the required fileds. Example: If you want
                                        to
                                        store phone Number than select the field name of phone number from the pabbly
                                        form
                                        and add it.
                                        <br />
                                        10. Make sure to select the list where you are willing to store your contact.
                                        Now
                                        click on save and test. You will see the data will reflect in Godial CRM within
                                        the
                                        assigned list.
                                        <br /> We provided a detailed video about the setup. You can check that out as
                                        well.
                                    </p>
                                </div>
                                <!-- <p class="text-left">
                          For more details visit :
                          <a
                            target="_blank"
                            href="https://help.indiamart.com/knowledge-base/lms-crm-integration/"
                          >
                            this link</a
                          >
                        </p> -->
                            </div>
                        </v-card-text>
                        <v-footer color="transparent" absolute>
                            <v-flex style="text-align: center !important">
                                <p style="
                            font-size: 12px;
                            opacity: 0.9;
                            margin-bottom: 0px;
                          ">
                                    Feel free to reach out to us at
                                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                                    if you are interested in these features
                                </p>
                            </v-flex>
                        </v-footer>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card flat>
                        <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                            <v-layout row wrap>
                                <!-- <v-flex d-flex justify-center class="mt-10" style="padding-top: 22px">Enable Pabbly
                                </v-flex> -->
                                <!-- <v-flex class="mt-10" d-flex justify-center>
                                    <v-switch v-model="pabbly.enabled" color="primary"></v-switch>
                                </v-flex> -->
                            </v-layout>
                        </v-flex>
                    </v-card>
                    <v-card class="d-flex align-center" color="transparent" elevation="0"
                        style="margin-top:25px; padding: 15px 0px; text-align: center; height: 100%;">
                        <div class="video-container">
                            <iframe class="tutorial" src="https://www.youtube.com/embed/bYAj-4uLVjk?si=ivGavjodJlsQ7x2T"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>

        <!-- Loader -->
        <v-dialog v-model="loader" persistent width="300">
            <v-card color="primary white--text" dark>
                <v-card-text style="padding: 18px 25px">
                    <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
                        Please Wait...
                    </h3>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

export default {
    data() {
        return {
            pabbly: {
                type: "pabbly",
                config: {
                    // email: "",
                    userId: "",
                    profileId: "",
                    inquiry_key: "",
                    inquiry_listId: "",
                    buyleads_key: "",
                    buyleads_listId: "",
                },
                listId: "",
                companyId: "",
                enabled: false,
            },
            loader: false,
            lists: [],
        };
    },

    computed: {
        ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
    },
    watch: {},
    created() {
        this.fetchLists();
        this.getTradeIndiaDetails();
        this.pabbly.companyId = this.companyDetails.id;

        console.log(this.companyDetails.id);
    },
    methods: {
        async getTradeIndiaDetails() {
            var resp = await this.$http.get(
                `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=tradeindia`
            );

            console.log("trade india data", resp.body);

            if (resp.body.length > 0) this.pabbly = resp.body[0];
        },
        getTeamIds() {
            var teams = [];
            if (this.selectedTeam == "allTeams") {
                teams = _.map(this.teams, "id");
            } else {
                teams.push(this.selectedTeam);
            }

            teams = _.without(teams, "allTeams");

            return teams;
        },
        async fetchLists() {
            try {
                this.lists = [];
                var response = [];

                var teamIds = this.getTeamIds();

                // Teams sections
                response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
                    teams: teamIds,
                });
                this.lists = response.body;

                if (this.lists.length < 1) {
                    this.loader = false;
                    return;
                }
            } catch (e) {
                this.loader = false;
                console.log("error in list fetch", e);
            }
        },

        async setTradeIndia() {
            this.loader = true;
            try {
                this.pabbly.companyId = this.companyDetails.id;
                this.pabbly.type = "tradeindia";
                this.pabbly.config.email = `ti-${this.companyDetails.id}@mg.godial.cc`;
                var res = {};
                //If new user
                if (typeof this.pabbly.id == "undefined") {
                    // Add Indiamart Details In Company Data
                    res = await this.$http.post(
                        `${this.ENDPOINT}/integrations`,
                        this.pabbly
                    );
                } else {
                    var data = JSON.parse(JSON.stringify(this.pabbly));
                    delete data.id;
                    res = await this.$http.put(
                        `${this.ENDPOINT}/integrations/${this.pabbly.id}`,
                        data
                    );
                }
                // Success Message
                if (res.status == 200) {
                    // Set Company Object for Store dispatch
                    this.getTradeIndiaDetails();
                    this.loader = false;
                    try {
                        fb.log("tradeIndia_added");
                    } catch (e) {
                        console.log("Error in FB log", e);
                    }
                    return this.$swal({
                        type: "success",
                        text: "TradeIndia Added to Godial Successfully",
                    });
                } else {
                    return this.$swal({
                        type: "warning",
                        text: "Something Went Wrong",
                    });
                }
            } catch (err) {
                console.log(err);
                this.loader = false;
                return this.$swal({
                    type: "warning",
                    text: err.body.error.message,
                });
            }
        },
    },
};
</script>

<style>
.tutorial {
    width: 100%;
    min-height: 40vh;
    height: auto;
    aspect-ratio: 16/9;
}

.video-container {
    min-width: 20rem;
    width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
}
</style>